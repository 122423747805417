.rooms {
  display: flex;
  width: 100%;
  max-width: rem(1290);

  margin: 0 auto;

  flex-direction: column;
  align-items: center;

  @include breakpoint($tablet) {
	max-width: rem(850);

	flex-direction: row;
	flex-wrap: wrap;

	justify-content: space-between;
  }

  @include breakpoint($desktop) {
	max-width: rem(1290);

	justify-content: flex-start;
  }

//   @include breakpoint(1290px) {
// 	justify-content: space-between;
//   }

  &-group {
    @include breakpoint($desktop) {
      padding-bottom: rem(400);
    }
  }
}

.room {
  display: flex;
  width: 100%;
  max-width: rem(410);
  height: rem(235);

  margin-bottom: rem(30);

  position: relative;

  flex-direction: column;

  background: url($vera-thumbnail-rooms-2-bg) no-repeat scroll center center transparent;
  background-size: cover;

  border-radius: rem(30);

  transition: background 0.2s linear;

  @include breakpoint($tablet) {
	width: calc(100% / 2 - 1%);

	margin-bottom: rem(30);

	justify-content: space-between;
  }

  @include breakpoint($desktop) {
	width: calc(100% / 3 - 1%);
	max-width: none;

	margin-right: 1.5%;

	background-size: 120%;

	&:nth-child(3n) {
		margin-right: 0;
	}

    &:hover {
      background-size: 125%;
    }
  }


  &.is-full {
    background-image: url($vera-thumbnail-rooms-bg);

    &:after {
      content: '';
      width: rem(80);
      height: rem(80);

      position: absolute;
      top: -30px;
      right: -30px;

      background: url($vera-checkmark) no-repeat scroll center center transparent;
      background-size: cover;
    }
  }

  &__info {
    display: flex;

    margin-top: auto;
    padding: 0 rem(25) rem(18);

    justify-content: space-between;
  }

  &__number {
    font-weight: 700;
    font-size: rem(31);
    letter-spacing: rem(2);
  }

  &__language {
    font-weight: 700;
    font-size: rem(14);
    letter-spacing: rem(2);
    text-transform: uppercase;
  }

  &__right {
    display: flex;
    margin-top: auto;

    p {
      margin: 0;

      font-size: rem(25);

      span {
        color: map-get($map: $colors, $key: green);
      }
    }
  }

  &__button {
    display: flex;

    width: 100%;
    height: 100%;

    padding: rem(25);

    position: absolute;

    align-items: center;

    background-color: rgba(0, 0, 0, 0.4);

    border-radius: rem(30);
  }
}
