.ticket-info {
	&-group {
		margin: rem(15) 0;

		@include breakpoint($tablet) {
			margin: rem(20) 0;
		}
	}

	padding: rem(20) rem(12);

	position: relative;

	background-color: $vera-bg-color;
	box-shadow: 0 0 rem(49) rgba(44, 127, 224, 0.56);
	border-radius: rem(29);

	@include breakpoint($tablet) {
		background-color: transparent;
		box-shadow: none;
		border-radius: 0;
	}

	&__image {
		img {
			width: 100%;
			height: auto;
		}
	}

	&__content {
		display: flex;
		width: 100%;
		height: 100%;

		flex-direction: column;

		padding: rem(30) rem(16);

		justify-content: center;

		@include breakpoint($tablet) {
			padding: rem(10) rem(25) rem(10) rem(60);

			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__box {
		display: flex;
		width: 100%;

		flex-direction: column;

		&--ticket {
			margin-bottom: rem(100);

			@include breakpoint($tablet) {
				margin-bottom: rem(40);
			}

			@include breakpoint($desktop) {
				margin-bottom: rem(80);
			}
		}

	}

	&__title {
		@extend .text-outer-glow;

		margin: 0 0 rem(15) 0;

		font-size: rem(16);
		letter-spacing: rem(2);
		text-transform: uppercase;

		@include breakpoint($tablet) {
			margin: 0 0 rem(20) 0;

			font-size: rem(20)
		}

		@include breakpoint($desktop) {
			margin: 0 0 rem(30) 0;

			font-size: rem(24)
		}
	}

	&__text {
		margin: 0 0 rem(10) 0;

		font-size: rem(12);

		span {
			color: $vera-green;
			text-transform: uppercase;
		}

		@include breakpoint($tablet) {
			font-size: rem(16)
		}

		@include breakpoint($desktop) {
			font-size: rem(18)
		}

		.ticket-info__box--rooms & {
			text-transform: uppercase;
		}
	}
}
