.table {
	color: #ffffff;

	&--order {
		&__item {
			display: flex;

			margin-bottom: rem(5);

			&:last-of-type {
				margin-bottom: 0;
			}

			&__quantity {
				margin-right: rem(15);
				font-weight: 900;
			}
		}
	}

	&--column__button {
		width: 1px;
		padding: 0.3rem 0.5rem;
	}

	.short-column {
		width: 1px;
	}
}
