.register-success {
	&-group {
		margin: rem(15) 0;

		@include breakpoint($tablet) {
			margin: rem(20) 0;
		}
	}

	&__content {
		padding: rem(30) rem(25);

		text-align: center;

		background-color: $vera-bg-color;
		border: rem(2) solid $vera-green;
		border-radius: rem(29);
		box-shadow: 0 0 rem(54) rgba(1, 226, 125, 0.7);

		@include breakpoint($tablet) {
			padding: rem(60) rem(25);

			border: rem(7) solid $vera-green;
		}
	}

	&__image {
		display: none;
		width: 100%;

		justify-content: center;

		img {
			display: flex;

			width: 100%;
			max-width: 100%;
			height: auto;
		}

		@include breakpoint($tablet) {
			display: flex;
		}
	}

	&__title {
		@extend .text-outer-glow;

		margin: 0;

		text-transform: uppercase;
		letter-spacing: rem(2);
		font-size: rem(16);

		@include breakpoint($tablet) {
			font-size: rem(36);
		}
	}

	&__text {
		@extend .text-outer-glow;

		margin: 0;

		text-transform: uppercase;
		letter-spacing: rem(2);
		font-size: rem(12);
		font-weight: 900;

		@include breakpoint($tablet) {
			font-size: rem(24);
		}
	}
}
