.status-box {
    display: flex;
    width: rem(30);
    height: rem(30);

    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    font-size: 0;

    border-radius: 100%;

    background-color: map-get($map: $colors, $key: green-1);

    @include breakpoint($tablet) {
        width: auto;
        height: auto;
        padding: .5rem 2rem;
        border-radius: rem(17);
        font-size: rem(16);
        white-space: nowrap;
        line-height: 100%;
    }

    &--unclaimed {
        background-color: map-get($map: $colors, $key: raspberry);
    }

    &--in-progress {
        background-color: map-get($map: $colors, $key: orange);
    }

    &--legend {
        width: rem(175);
        height: rem(33);

        font-size: rem(13);

        border-radius: rem(17);
    }

    &--smaller {
        width: rem(130);
        height: rem(25);

        font-size: rem(10);
        font-weight: 500;

        border-radius: rem(17);
    }
}
