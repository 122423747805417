table.tickets {
	margin: 0 -0.75rem;

	thead th{
		font-weight: 700;
		font-size: rem(14);
		text-transform: uppercase;
		text-shadow: 0 rem(-2) rem(15) rgba(15, 171, 246, 0.56);
		letter-spacing: rem(2);

		@include breakpoint($tablet) {
			font-size: rem(21);
			letter-spacing: 0;
		}
	}

	tbody td {
		padding-top: .5rem;
		padding-bottom: .5rem;
		vertical-align: middle;


		@include breakpoint($tablet) {
			.status-box {
				width: calc(100% - 27px);
			}
		}
	}

	&__share {
		align-self: center;
		margin-left: .5rem;
		opacity: 1;
		cursor: pointer;
	}
}

.tickets {
	&__item {
		display: flex;

		margin-bottom: rem(50);

		font-weight: 500;
		font-size: rem(20);

		@include breakpoint($tablet) {
			margin-bottom: rem(13);

			font-size: rem(14);
		}

	}

	&__person {
		display: flex;
		flex: 1;
		padding-left: rem(20);

		align-items: center;

		font-size: rem(18);

		@include breakpoint(0, $tablet) {
			display: flex;

			flex-direction: column;

			align-items: flex-start;

			font-size: rem(17);
		}

		&--unclaimed {
			text-transform: uppercase;
			color: map-get($map: $colors, $key: raspberry);
		}

		&__name {
			font-weight: 700;
		}
	}
}
