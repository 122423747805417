.alert {
  display: flex;

  padding: rem(10) rem(20);
  margin-bottom: rem(20);

  color: $white;

  border: rem(1) solid $white;
  border-radius: rem(3);

  &--success {
    background: rgba(0, 255, 0, 0.25);
	  border-color: rgba(0, 255, 0, 0.25);
  }
  &--info {
    background-color: rgb(52, 144, 220, 0.3);
	  border-color: rgb(52, 144, 220, 0.75);
  }
  &--danger {
    background: rgba(255, 0, 0, 0.25);
	  border-color: rgba(255, 0, 0, 0.25);
  }
}
