html {
  box-sizing: border-box;

  // overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.main {
	margin-bottom: 10vh;
}

body {
  display: flex;
  width: 100%;
  min-height: 100vh;

  flex-direction: column;

  font-family: $font-main;
  color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: url($main-bg-mobile) no-repeat scroll center top map-get($map: $colors, $key: dark) !important;

  @include breakpoint($tablet) {
    background: url($main-bg) no-repeat scroll center top map-get($map: $colors, $key: dark) !important;
  }
}

.section {
  display: flex;
  width: 100%;

  padding: rem(30) 0;

  @include breakpoint($tablet) {
    padding: rem(60) 0;
  }

  &--rooms {
    @include breakpoint($desktop) {
      background: url('/img/distr/spacey.png') no-repeat scroll left bottom transparent;
    }
  }

  &--stats {
    @include breakpoint($desktop) {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: rem(1140);

        position: absolute;
        bottom: -380px;
        left: 50%;
        z-index: -1;

        transform: translateX(-50%);

        background: url('/img/distr/ring.png') no-repeat scroll center bottom transparent;
      }
    }
  }

  &--dashboard {
    padding: rem(30) rem(25);

    @include breakpoint($tablet) {
      &:last-of-type {
        margin-bottom: rem(100);
      }
    }

    .section__content {
      width: 100%;
      max-width: rem(890);

      margin: 0 auto;
    }
  }

  &__group {
    display: flex;
    width: 100%;
    // max-width: rem(1745);
    max-width: rem(1360);
    flex-direction: column;

    padding: 0 rem(25);
    margin: 0 auto;

    .section--dashboard & {
      @extend .box;

      max-width: rem(1230);

      padding-bottom: rem(25);

      @include breakpoint($tablet) {
        padding-bottom: rem(75);
        padding-top: rem(55);
      }
    }

    &--row {
      flex-direction: column;

      @include breakpoint($tablet) {
        flex-direction: row;
      }
    }
  }

  &__title {
    display: flex;
    width: 100%;

    justify-content: center;

    h1 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: rem(25);
      text-align: center;
      letter-spacing: rem(2);
      text-shadow: 0 rem(-2) rem(15) rgba(15, 171, 246, 0.56);

      @include breakpoint($tablet) {
        margin-bottom: rem(60);

        font-size: rem(36);
      }
    }

  }

  &__table {
	  margin-top: rem(30);

	  overflow-x: scroll;

	  @include breakpoint(1000px) {
		overflow: hidden;
	  }

	  table {
		  width: 100%;
		  min-width: rem(1000);

		  border-collapse: collapse;

		  thead {
			  text-align: left;
		  }

		  th {
			  text-shadow: 0 -0.125rem 0.9375rem rgba(15, 171, 246, 0.56);

			  padding: rem(10);

			  font-size: rem(21);
			  text-transform: uppercase;

			  &:first-child {
				  padding-left: 0;
			  }
		  }

		  td {
			padding: rem(10) rem(10);

			font-size: rem(16);
			vertical-align: top;

			border-bottom: rem(1) solid rgba(255, 255, 255, 0.2);

			&:first-child {
				padding-left: 0;
			}
		  }

		  tr {
			  &:last-child {
					td {
						border: none;
					}
			  }
		  }
	  }
  }

	&__button {
		display: flex;
		margin-top: rem(20);

		justify-content: center;

		@include breakpoint($tablet) {
		margin-top: rem(50);
		}
	}
}

p {
  margin: 0 0 rem(20) 0;
}

ul {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

a {
  color: $white;
  text-decoration: none;

  &:hover {
    color: $white !important;
  }
}

img {
  display: block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $white;
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px map-get($map: $colors, $key: dark) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.text-outer-glow {
  text-shadow: 0 0 rem(49) rgba(15, 171, 246, 0.56);
}

.box {
  border-radius: rem(40);
  background-color: rgba(1, 11, 23, 0.56);
  box-shadow: 0 0 rem(49) rgba(44, 127, 224, 0.56);
}

.question-icon {
  display: flex;
  width: rem(30);
  height: rem(30);
  min-width: rem(30);

  margin-left: rem(12);

  font-weight: 900;
  font-size: rem(20);
  letter-spacing: 0;


  justify-content: center;
  align-items: center;

  border-radius: 100%;

  background-color: #878787;

  cursor: pointer;
}
