.statistics {
    margin: 0 0.75rem;

    th {
        font-size: rem(21);
        text-transform: uppercase;
        text-shadow: 0 rem(-2) rem(15) rgba(15, 171, 246, 0.56);
        letter-spacing: rem(2);
    }

    td {
        font-weight: 500;
        font-size: rem(20);

        @include breakpoint($tablet) {
            font-size: rem(24);
        }

        .vera-green {
            color: $vera-green;
            font-weight: 700;
        }
    }
}
