.button {
  display: flex;
  width: 100%;
  min-height: rem(50);

  align-items: center;
  justify-content: center;

  padding: rem(10) rem(20);

  color: $white;
  text-align: center;
  font-size: rem(20);
  font-weight: 500;
  text-shadow: 2px 3px 10px rgba(0,0,0,0.89);
  text-transform: uppercase;

  border: none;
  border-radius: rem(40);

  background-color: map-get($map: $colors, $key: blue);

  outline: none;

  cursor: pointer;

  will-change: background-color;
  transition: background-color 0.2s linear;

  @include breakpoint($tablet) {
    height: rem(84);

    padding: rem(10) rem(40);

    font-size: rem(36);
  }
}

.button:hover {
  background-color: darken(map-get($map: $colors, $key: blue), 10%);
}

.button--room {
  height: rem(50);

  font-size: rem(18);
}

.button--upgrade {
  background-color: map-get($map: $colors, $key: light-orange);
  border: rem(1) solid #000;
  box-shadow: 0 0 rem(79) rgba(206, 181, 148, 0.45);

  @include breakpoint($tablet) {
    font-size: rem(24);
  }

  &:hover {
    background-color: darken(map-get($colors, light-orange), 10%);
  }
}

.button--maxw {
	max-width: rem(708);
}

.has-icon {
	span {
		display: inline-flex;

		margin-left: rem(20);

		@include breakpoint($tablet) {
			margin-left: rem(30);
		}
	}

	&--locked {
		img {
			width: rem(20);
			height: auto;

			@include breakpoint($tablet) {
				width: rem(34);
			}
		}
	}
}

.nav-tabs {
    width: 100%;
    flex-direction: column;

    @include breakpoint($mobile) {
        width: auto;
        flex-direction: row;
    }

    .nav-link {
        padding: 0.5rem 1.25rem;
        border-color: #e9ecef;
        border-radius: 0;
        margin-bottom: 0;
        text-align: center;

        font-size: 1.25rem;

        @include breakpoint($tablet) {
            padding: 1rem 2.5rem;
            font-size: 1.5rem;
        }

        &:not(.active):hover {
            background-color: rgba(255, 255, 255, 0.2);
        }

        &.active:hover {
            color: #495057 !important;
        }
    }

    li:first-child .nav-link {
        border-bottom: 0;
        border-radius: 2.5rem 2.5rem 0 0;

        @include breakpoint($mobile) {
            border-bottom: 1px solid #e9ecef;
            border-right: 0;
            border-radius: 2.5rem 0 0 2.5rem;
        }
    }

    li:last-child .nav-link {
        border-top: 0;
        border-radius: 0 0 2.5rem 2.5rem;

        @include breakpoint($mobile) {
            border-top: 1px solid #e9ecef;
            border-left: 0;
            border-radius: 0 2.5rem 2.5rem 0;
        }
    }
}