.footer {
  display: flex;
  width: 100%;
  min-height: rem(265);

  margin-top: auto;
  padding: 0;

  position: relative;

  background: rgb(12, 60, 122);
	background: -moz-radial-gradient(circle, rgba(12, 60, 122, 1) 7%, rgba(9, 23, 42, 1) 66%);
	background: -webkit-radial-gradient(circle, rgba(12, 60, 122, 1) 7%, rgba(9, 23, 42, 1) 66%);
	background: radial-gradient(circle, rgba(12, 60, 122, 1) 7%, rgba(9, 23, 42, 1) 66%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c3c7a", endColorstr="#09172a", GradientType=1);

  &__content {
    position: relative;

    justify-content: center;
    align-items: center;

    @include breakpoint($tablet) {
      align-items: flex-end;
    }
  }

  &__image {
    margin-bottom: rem(20);

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    @include breakpoint($tablet) {
      margin-bottom: 0;

      position: absolute;
      left: 50%;
      top: -50%;

      transform: translateY(-43%) translateX(-50%);
    }
  }

  &__design {
    margin-bottom: rem(20);

    @include breakpoint($tablet) {
      margin-bottom: 0;
    }
  }

  &__support {
    display: flex;

    align-items: center;

    @include breakpoint($tablet) {
      position: absolute;
      right: rem(25);
    }

    &__text {
      margin-right: rem(20);

      font-weight: 600;
      font-size: rem(18);
      text-transform: uppercase;
      letter-spacing: rem(2);
      text-shadow: 0 0 15px $white;
      color: $white;

      p {
        margin: 0;
      }

      @include breakpoint($tablet) {
        font-weight: 700;
        font-size: rem(21);
        letter-spacing: rem(2);
      }
    }

    &__image {
      width: rem(70);

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      @include breakpoint($tablet) {
        width: rem(99);
      }
    }
  }
}
