.upgrade-message {
  &-group {
    padding: rem(30);

    background-color: rgba(1, 11,23, 0.6);
    box-shadow: 0 0 rem(49) rgba(206, 181, 148, 0.8);
    border-radius: rem(30);
  }

  width: 100%;
  max-width: rem(635);

  margin: 0 auto;
  padding: rem(50) rem(30);

  &__title {
    @extend .text-outer-glow;

    margin: 0 0 rem(40) 0;

    text-transform: uppercase;
    font-size: rem(24);
    font-weight: 900;
    letter-spacing: rem(2);
    text-align: center;
  }

  &__text {
    margin: 0 0 rem(35) 0;

    font-size: rem(18);
    line-height: line-height(18, 25);
    font-weight: 500;
    text-align: center;
    text-shadow: rem(8) 0 rem(18) rgba(0, 0, 0, 0.9);
  }

}
