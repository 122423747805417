.header {
    display: flex;
    max-width: rem(1745);

    margin: 0 auto;
    padding: rem(30) rem(25);

    justify-content: space-between;

    &__logo {
        max-width: rem(100);

        img {
            width: 100%;
            height: auto;
        }

        @include breakpoint($desktop) {
            width: rem(200);
            max-width: none;

            img {
                width: rem(187);
            }

            .logged-in {
                img {
                    width: rem(144);
                }
            }
        }
    }

    .menu-trigger {
        display: flex;
        width: rem(40);
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        span {
            display: block;
            width: 100%;
            height: rem(2);

            background-color: $white;

            &:nth-child(2) {
                margin: rem(10) 0;
            }
        }

        @include breakpoint($mobile) {
            margin-left: auto;
        }

        @include breakpoint($desktop) {
            display: none;
        }
    }

    .event-logo {
        display: none;
        max-width: rem(237);
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }

        @include breakpoint($desktop) {
            display: flex;
        }
    }

    &__logout {
        display: none;

        @include breakpoint($desktop) {
            display: inline-flex;
            width: rem(200);

            justify-content: flex-end;
        }
    }

    &__buttons {
        @include breakpoint($mobile) {
            margin-left: 2rem;
        }
    }
}

.navigation {
    display: flex;
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    padding: rem(90) rem(25) 0;

    background-color: map-get($map: $colors, $key: dark);

    transform: translateX(-100%);

    transition: transform 0.2s linear;

    .menu-open & {
        transform: translateX(0);
    }

    @include breakpoint($desktop) {
        display: flex;
        width: auto;
        height: auto;

        flex: 1;

        padding: 0;

        position: relative;
        left: unset;
        top: unset;

        justify-content: center;

        background-color: transparent;

        transform: translateX(0);
    }

    &__list {
        display: flex;

        flex-direction: column;

        @include breakpoint($desktop) {
            width: 100%;

            flex-direction: row;

            justify-content: center;
        }

        li {
            margin-bottom: rem(25);

            @include breakpoint($desktop) {
                margin: 0 rem(15);
            }
        }
    }

    .navigation__logout {
        @include breakpoint($desktop) {
            display: none;
        }
    }

    &__link {
        position: relative;

        font-weight: 500;
        font-size: rem(15);
        line-height: line-height(15, 19);
        text-transform: uppercase;
        color: $white;

        &:hover {
            &:after {
                opacity: 1;
            }
        }

        .subtitle {
            font-size: .6rem;
            text-transform: none;
        }

        @include breakpoint($desktop) {
            &:not(:last-of-type) {
                margin-right: rem(15);
            }

            &:after {
                content: '';
                display: block;

                width: 100%;
                height: rem(10);

                position: absolute;
                top: -49px;
                left: 0;

                opacity: 0;

                filter: blur(10px);

                transition: opacity 0.2s linear;

                background-color: map-get($map: $colors, $key: blue);
            }
        }

        @include breakpoint($desktop) {
            font-size: rem(16);
            line-height: line-height(18, 19);
        }
    }

    .close {
        position: absolute;
        right: rem(32);
        top: rem(32);
        width: rem(32);
        height: rem(32);
        opacity: 0.3;

        &:hover {
            opacity: 1;
        }

        &:before,
        &:after {
            position: absolute;
            left: rem(15);
            content: ' ';
            height: rem(33);
            width: rem(2);
            background-color: $white;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        @include breakpoint($desktop) {
            display: none;
        }
    }
}
