// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

//------------------------------------------
//login/Register
#login,
#register {
	padding: 25vh 0;
}


//------------------------------------------
//BASE

.sage-button {
	margin-bottom: 2em;
}

.sage-button-disabled a {
	background-color: grey;
	cursor: default;
}

.sage-button-disabled a:hover {
	background-color: #5d5d5d;
}

.tech-check-notice {
	margin-bottom: 1em;
	font-size: 2em;
	text-align: center;
	color: red;
}

.sage-next-tech-check {
	text-align: center;
	font-size: 2em;
	margin-bottom: 1em;
}

.vera-glow {
	text-align: center;
	text-shadow: 0 0 50px #047ee4;;
}

.nav-p-3 {
	padding-top: 3em;
}

body {
	// background-color: $vera-bg-color;
}

p.vera-f-sm,
.vera-f-sm {
	margin: 0;
	font-size: $vera-sm !important;
}

p.vera-f-m,
.vera-f-m {
	margin: 0;
	font-size: $vera-m !important;
}

p.vera-f-lg,
.vera-f-lg {
	margin: 0;
	font-size: $vera-lg !important;
}


p.vera-f-xx,
.vera-f-xx {
	margin: 0;
	font-size: 2em !important;
}

.vera-grow {
	flex-grow: 1;

	.vera-card,
	.vera-card-sm {
		display: flex !important;
	}
}

.vera-row {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.vera-center {
	text-align: center;
}

.vera-stats-user {
	div {
		p {
			font-size: $vera-m;
			color: $vera-green;
		}
	}
}

.vera-container {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;

	img {
		width: 100%;
	}
}

.vera.vera-stats {
	h1 {
		padding: 0.2em;
	}
}

.vera-vcontainer {
	@extend .vera-container;
	flex-direction: column;
}

.vera {
	@extend .vera-glow;
	color: white;
	text-transform: uppercase;
	letter-spacing: 3.5px;

	h1, h2, h3 {
		text-align: center;
	}

	h1 {
		font-size: $vera-big;
	}

	h2 {
		font-size: $vera-m;
	}

	h3 {
		font-size: $vera-sm;
	}
}

//------------------------------------------
//COUNTDOWN
.vera-countdown {
	// padding: 2em 0;
	text-align: center;
}

.vera-countdown-cards {
	p:last-child {
		padding: 0;
		margin: 0;
	}
}

//------------------------------------------
//COLORS
.vera-c-green {
	color: $vera-green !important;
}

//------------------------------------------
//Home
#vera-brand {
	color: white;
	font-weight: bold;
	font-size: 1.25rem;
	text-transform: uppercase;

	.vera-brand-sub {
		color: $vera-gold;
		font-weight: bold;
		display: block;
		font-size: 0.65em;
		margin: -9px 0;
	}
}

.vera-rooms {
	margin: 5em 0;
	.col-md-4 {
		padding: 15px;
	}
}

.vera-support {
	position: absolute;
	right: 5%;
	float: left;

	p {
		letter-spacing: 3px;
		font-size: $vera-m;
		font-weight: bold;
		width: 65%;
		float: left;
		color: white;
		text-transform: uppercase;
	}

	.vera-img-w {
		float: right;
		width: 35%;
	}
}

//------------------------------------------
//Menu
.navbar-light .navbar-toggler {
	color: white !important;
}


.vera-nav-items {
	.nav-item {
		text-decoration: none;
		background-color: transparent;
		text-transform: uppercase;
		margin-right: 1em;
		font-size: 0.85em;

		a {
			color: white;
		}
	}
}

.vera-nav-items .nav-item a:hover {
	text-decoration: none !important;
	color: rgba(255, 255, 255, 0.7) !important;
	transition: all 1s;
}

//------------------------------------------
//Images
.vera-bg {
	background: url($main-bg) no-repeat;
	background-size: cover;
	background-position: top center;
}

.vera-main-container {
	min-height: 80vh;
	padding-bottom: 125px;
}

.vera-base-img {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 10vw;
	height: 10vw;
	margin-left: -2vw;
}

.vera-made-by {
	@extend .vera-base-img;
	padding: 4em 0;
	width: 165px !important;
	margin: 0 auto;
	height: auto;
}

.vera-nmpi-logo {
	@extend .vera-base-img;
	width: 165px !important;
	height: auto;
	margin: -125px auto 0 auto;
}

.vera-support-img {
	@extend .vera-base-img;
	background-image: url($vera-support-bg);
	width: 6vw;
	height: 5vw;
	margin: 0;
}

.vera-zoom-bg {
	@extend .vera-base-img;
	width: 100%;
	background-image: url($vera-zoom-bg);
	margin-left: unset;
}

.vera-room-card-bg {
	@extend .vera-base-img;
	overflow: hidden;
	background-size: 110%;
	transition: background-size .5s ease;
}

.vera-room-card-bg:hover {
	background-size: 125%;
}

.vera-rooms-1-bg {
	@extend .vera-room-card-bg;
	background-image: url($vera-thumbnail-rooms-bg);
}


.vera-rooms-2-bg {
	@extend .vera-room-card-bg;
	background-image: url($vera-thumbnail-rooms-2-bg);
}


.vera-room-full {
	@extend .vera-base-img;
	background-image: url($vera-checkmark);
	width: 64px;
	height: 64px;
}

.vera-logout {
	background-image: url($vera-logout);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	width: 32px;
	height: 32px;
}

.vera-logout:hover {
	background-color: rgba(0, 0, 0, 0.33);
	border-radius: 5px;
	transition: all 1s;
	transition-timing-function: ease;
}

.vera-profile {
	background-image: url($vera-profile);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	width: 32px;
	height: 32px;
}

.vera-profile:hover {
	background-color: rgba(0, 0, 0, 0.33);
	border-radius: 5px;
	transition: all 1s;
	transition-timing-function: ease;
}


//------------------------------------------
//CARDS

.vera-card {
	//flex-grow: 1;
	display: inline-block;
	width: auto;
	padding: 1em 3em;
	margin: 1em;
	border-radius: 1em;
	text-align: center;

	&.background {
		background-color: $vera-transparent-bg;
	}

	h1 {
		font-weight: bold;
	}

	h1, h2, h3 {
		letter-spacing: 5px;
		padding: 1em 0;
		display: block;
		width: 100%;
	}

	p {
		display: block;
		font-size: $vera-big;
		font-weight: bold;
	}
}

.vera-card-bg {
	background-color: $vera-transparent-bg;
	border-radius: 1em;
}

.vera-card-sm {
	@extend .vera-card;
}

.vera-card-lg {
	padding: 3em 0;
	margin: 0;
	@extend  .vera-card;

}

.vera-room-card-locked {
	//TODO: implement
}

.vera-room-card {
	position: relative;
	display: table;
	border-radius: 1em;
	width: 100%;
	max-width: 400px;
	height: 180px;
	margin: 0 auto;

	.vera-room-no-lang {
		display: table-cell;
		vertical-align: bottom;
		width: 50%;

		p {
			padding-left: 1rem;
			text-align: left;
		}

		p:first-child {
			font-weight: bold;
		}
		p:last-child {
			text-transform: uppercase;
		}
	}

	.vera-room-logo {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}

	.vera-room-no-lang {
		padding-bottom: 1rem;
	}

	.vera-room-no {
		text-align: right;
		display: table-cell;
		vertical-align: bottom;
		padding-right: 1rem;
		padding-bottom: 1rem;
	}

	.vera-room-full {
		display: table-cell;
		overflow-wrap: break-word;
		position: absolute;
		top: 0;
		right: -7px;
	}
}

.vera-tr {
	display: table-row;
}

.vera-td {
	text-align: center;
	vertical-align: middle;
	display: table-cell;
}

.vera-col {
	display: table-column;
}



//------------------------------------------
//Title
.vera-title {
	//flex-grow: 1;
	padding: 1em 0;
	margin: 1em 0;
	//display: inline-flex;

	h1, h2, h3 {
		display: block;
		text-align: center;
		width: 100%;
		margin: 0;
	}

	h1 {
		font-size: $vera-big;
	}

	h2 {
		font-size: $vera-m;
	}

	h3 {
		font-size: $vera-sm;
	}

	//background-color: $vera-transparent-bg;
	border-radius: 1em;

	p {
		text-align: center;
	}
}


//------------------------------------------
//Stats


//------------------------------------------
//Circles

.circle {
	margin: 20px;
	width: 200px;
	height: 200px;
}

// .vera-circle {
// 	font-size: $vera-m;
// 	position: relative;
// 	display: table;
// 	//border: 5px solid $vera-green;
// 	width: 200px;
// 	height: 200px;
// 	//border-radius: 50%;
// 	margin: 0 auto;
// 	.vera-card, .vera-card-sm {
// 		display: table-cell;
// 		vertical-align: middle;
// 		background-color: unset;
// 	}

// 	.progressbar-text {
// 		font-size: $vera-m !important;
// 		font-weight: bold;
// 		text-align: center;

// 		p:first-child {
// 			font-weight: bold;
// 			font-size: 2em !important;
// 		}

// 		p:last-child {
// 			font-weight: normal;
// 			font-size: 0.5em !important;
// 			margin-top: -15px;
// 		}
// 	}
// }

//------------------------------------------
//FOOTER
footer {
	width: 100%;
	padding: 3em;
	background-color: $vera-footer;
}

.vera-gradient {
	background: rgb(12, 60, 122);
	background: -moz-radial-gradient(circle, rgba(12, 60, 122, 1) 7%, rgba(9, 23, 42, 1) 66%);
	background: -webkit-radial-gradient(circle, rgba(12, 60, 122, 1) 7%, rgba(9, 23, 42, 1) 66%);
	background: radial-gradient(circle, rgba(12, 60, 122, 1) 7%, rgba(9, 23, 42, 1) 66%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c3c7a", endColorstr="#09172a", GradientType=1);
}


//------------------------------------------
//RESPONSIVE DESIGN

@media screen and (max-width: 1206px) {
	#vera-distr-rank,
	#vera-distr-countdown {
		width: 100%;
	}
}

@media screen and (max-width: 1000px) {
	.vera-support {
		position: relative;
		display: block;
		right: unset;
		float: unset;

		p {
			width: unset;
			float: unset;
			font-size: $vera-sm;
			text-align: center;
		}

		.vera-img-w {
			float: unset;
			width: unset;

			.vera-support-img {
				margin: auto;
			}
		}
	}
}


@media screen and (max-width: 768px) {
	.vera-room-card {
		margin-bottom: 1em;
	}

	.vera-nmpi-logo {
		//bottom: -125px;
	}
}

//___
//OVERRIDE BASE


.card {
	background-color: rgba(0, 0, 0, 0.85) !important;
	color: white;
}

.form-control {
	background: rgba(0, 0, 0, 0.45);
	border: 1px solid rgba(255, 255, 255, 0.25);
	color: white;
}

.form-control:disabled, .form-control[readonly] {
	background: rgba(0, 0, 0, 0.85);
	border: 1px solid rgba(255, 255, 255, 0.25);
	color: rgba(255, 255, 255, 0.5) !important;
}

.form-control:focus {
	background: rgba(0, 0, 0, 0.45);
	color: white;
}

.alert {
	color: white;
}

.alert-success {
	background: rgba(0, 255, 0, 0.25);
	border-color: rgba(0, 255, 0, 0.25);
}

.alert-info {
	background-color: rgb(52, 144, 220, 0.3);
	border-color: rgb(52, 144, 220, 0.75);
}

.alert-danger {
	background: rgba(255, 0, 0, 0.25);
	border-color: rgba(255, 0, 0, 0.25);
}

.btn-primary {
	background-color: rgb(52, 144, 220, 0.3);
	border-color: rgb(52, 144, 220, 0.75);
}

.btn-primary:hover {
	background-color: rgb(52, 144, 220, 0.5);
}

.btn-green {
	background: rgba(0, 255, 0, 0.25);
	border-color: rgba(0, 255, 0, 0.25);
}

.btn-green:hover {
	background: rgba(0, 255, 0, 0.35);
	border-color: rgba(0, 255, 0, 0.35);
}

.hidden-information {
	display: none !important;
	height: 0 !important;
}

.home-section {
	.alert--success {
		border: 6px solid #01e27d;
		border-radius: 25px;
		background: #010b17;
		text-align: center;
		padding: 2em;
		font-size: 2em;
		box-shadow: 0px 0px 60px 20px #01e27d54;
		text-transform: uppercase;
	}
}
