.countdown {
	display: flex;

	// flex-direction: column;
	flex-wrap: wrap;

	justify-content: space-between;

	position: relative;

	@include breakpoint($tablet) {
	  &:after {
		content:'';
		display: inline-flex;
		width: calc(100% / 3 - 20px);
	  }
	}

	@include breakpoint($desktop) {
	  &:after {
		content:'';
		display: none;
	  }
	}

	&-alert {
	  display: none;
	}

	&__item {
	  @extend .box;
	  width: 48%;

	  margin: 0 0 rem(20) 0;
	  padding: rem(20);

	  text-align: center;

	  @include breakpoint($tablet) {
		width: calc(100% / 3 - 20px);

		padding: rem(40);
	  }

	  @include breakpoint($desktop) {
		width: calc(100% / 5 - 20px);

		margin: 0 0 0 0;
	  }

	  &:first-of-type {
		margin: 0 0 rem(20) 0;

		@include breakpoint($desktop) {
		  margin: 0 0 0 0;
		}
	  }

	  &:last-of-type {
		margin: 0;

		@include breakpoint($tablet) {
		  margin: 0 0 rem(20) 0;
		}

		@include breakpoint($desktop) {
		  margin: 0 0 0 0;
		}
	  }

	  h3 {
		@extend .text-outer-glow;

		font-size: rem(16);
		text-transform: uppercase;
		letter-spacing: 2px;

		margin: 0 0 rem(20) 0;

		@include breakpoint(1250px) {
		  font-size: rem(22);
		}
	  }

	  p {
		@extend .text-outer-glow;
		display: flex;

		margin: auto 0 0;

		flex-direction: row;

		font-weight: 700;
		font-size: rem(45);
		text-transform: uppercase;
		letter-spacing: 2px;

		justify-content: center;

		@include breakpoint($tablet) {
		  font-size: rem(60);
		}
	  }
	}
  }

.sage-js-countdown {
	border: none !important;
	margin: 0 !important;
	padding: 0 !important;
}
