.login {
  &-message {
    padding: rem(30) rem(25);

    text-align: center;

    background-color: $vera-bg-color;

    border: rem(4) solid $vera-green;
    border-radius: rem(29);

    box-shadow: 0 0 rem(82) rem(20) rgba(1, 226, 125, 0.7);

    & + .form-group .form {
      padding-top: rem(80);
    }

    @include breakpoint($tablet) {
      padding: rem(60) rem(25);
    }

    p {
      @extend .text-outer-glow;
      width: 100%;
      max-width: rem(850);

      margin: 0 auto;

      font-size: rem(25);
      font-weight: 900;
      letter-spacing: rem(2);
      text-transform: uppercase;

      @include breakpoint($tablet) {
        font-size: rem(34);
      }
    }
  }
}
