
.modal {
	// font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
}

.modal__overlay {
	max-width: 100%;
	margin: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10000;
}

.modal__container {
	max-width: 500px;
	max-height: 90vh;
	padding: 30px;
	border: 0;
	border-radius: 4px;
	box-sizing: border-box;
	background-color: #fff;
}

.modal__header {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.modal__title {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 600;
	font-size: rem(17);
	line-height: 1.25;
	box-sizing: border-box;
}

.modal__close {
	display: flex;
	width: rem(30);
	height: rem(30);

	font-weight: 900;
	font-size: rem(25);
	color: #00050a;

	position: absolute;
	top: 0;
	right: 0;

	justify-content: center;
	align-items: center;

	background-color: #fff;

	border-radius: 100%;
	border: 0;

	cursor: pointer;

	&:focus {
		outline: none;
	}

	&:before {
		content: "×";
	}

	&:only-child {
		position: static;
		margin-left: auto;
	}
}

.modal__content {
	margin-top: 2rem;
	line-height: 1.5;
	color: #ffffff;

	.tutorial-number {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background-image: radial-gradient(circle, rgba(225,0,96,1) 0%, rgba(225,0,96,0.5) 70%, rgba(225,0,96,0.2) 100%);
		border-radius: 50%;

		& + div {
			flex: 1 1 0;
		}
	}
}

.modal--share {
	.modal__title span {
		font-weight: 900;
		text-transform: uppercase;
		color: map-get($map: $colors, $key: orange);
	}

	.modal__container {
		width: 100%;
		max-width: rem(770);

		margin: 0 rem(25);

		background-color: rgba(14, 37, 65, 0.95);
		border-radius: rem(29);
		box-shadow: 0 0 rem(49) rgba(44, 127, 224, 0.56);

		@include breakpoint($tablet) {
			padding-top: rem(30);
		}
	}

	.modal__content {
		display: flex;

		margin-top: rem(20);

		flex-direction: column;
		align-items: center;
		overflow-y: auto;
	}

	.modal__email {
		font-size: rem(14);
		word-break: break-all;

		@include breakpoint($tablet) {
			font-size: rem(18);
		}
	}
}

.modal--status {
	.modal__container {
		margin: 0 rem(25);

		background-color: rgba(42, 46, 51, 0.95);
		border-radius: rem(29);

		h3 {
			margin: rem(10) 0;

			font-weight: 700;
			font-size: rem(13);
		}

		p {
			margin: 0 0 rem(30) 0;

			font-weight: 400;
			font-size: rem(13);
		}
	}
}
