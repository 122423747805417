.accordion {
    i.fa-chevron-down {
        transition: transform 0.2s;
        transform: rotateX(0deg);
    }

    .active i.fa-chevron-down {
        transform: rotateX(180deg);
    }

    .accordion-table-hide {
        display: none !important;
        @include breakpoint($tablet) {
            display: table-cell !important;
        }
    }
}