.form-group {
    display: flex;

    align-items: center;
    justify-content: center;

    &--tickets {
        .form-block {
            max-width: none;
        }
    }
}

.form {
    width: 100%;

    &--login {
        padding-top: rem(50);
        padding-bottom: rem(50);

        @include breakpoint($tablet) {
            padding-top: rem(150);
            padding-bottom: rem(130);
        }
    }

    &--ticket {
        margin-top: rem(30);

        @include breakpoint($tablet) {
            margin-top: rem(180);
            margin-bottom: rem(155);
        }
    }

    &--register,
    &--reset {
        padding-top: rem(20);
        padding-bottom: rem(50);

        @include breakpoint($tablet) {
            padding-top: 0;
            padding-bottom: rem(130);
        }
    }

    &--distribution {
        .button--submit {
            margin-top: rem(20);
        }
    }

    &__block {
        display: flex;
        width: 100%;
        max-width: rem(710);

        margin: 0 auto;

        flex-direction: column;

        &:not(:last-of-type) {
            margin-bottom: rem(40);
        }

        &--center {
            justify-content: center;
            align-items: center;
        }

        &--duo {
            display: flex;

            label {
                &.has-tooltip {
                    display: flex;

                    position: relative;

                    align-items: center;
                }
            }

            @include breakpoint($desktop) {
                flex-direction: row;
                flex-wrap: wrap;

                justify-content: space-between;

                label {
                    width: rem(255);
                    display: flex;

                    align-items: center;
                }

                .form__input,
                .form__select {
                    width: rem(445);
                }
            }
        }

        &--longer {
            max-width: none;

            label {
                font-weight: 700!important;
                text-shadow: 0 rem(-2) rem(15) rgba(15, 171, 246, 0.56)!important;
                letter-spacing: rem(2);
            }

            @include breakpoint($desktop) {
                .form__input,
                .form__select {
                    width: 580px;
                }

                label {
                    margin-bottom: rem(20) !important;
                    font-size: rem(21)!important;

                }
            }
        }

        &--announcement {
            display: flex;
            width: 100%;
            padding: 0.75rem 1.25rem;
            align-items: center;
            justify-content: center;
            text-transform: none;
            color: #ffffff;
            background-color: #010b17;
            border: 0.25rem solid #e2a201;
            border-radius: 1.8125rem;
            box-shadow: 0 0 3.125rem 0.3125rem rgb(189, 127, 0);
            will-change: box-shadow;
            transition: box-shadow 0.2s linear;
        }

        .invalid-feedback {
            display: flex;
            width: 100%;

            flex-basis: 100%;
            color: $red;
        }
    }

    label {
        margin-bottom: rem(20);

        font-weight: 500;
        font-size: rem(18);
        text-transform: uppercase;

        @include breakpoint($desktop) {
            margin-bottom: 0;
        }

    }

    &__input {
        width: 100%;
        height: rem(50);

        padding: 0 rem(20);

        font-size: rem(20);
        color: $white;

        background-color: transparent;
        border: rem(1) solid $white;
        border-radius: rem(40);

        outline: none;

        @include placeholder {
            color: $white;
            text-transform: uppercase;
            font-weight: 500;
            font-size: rem(24);
            // line-height: line-height(24, 27);
        }

        @include breakpoint($desktop) {
            height: rem(80);

            padding: 0 rem(40);

            font-size: rem(24);
        }

        &.is-invalid {
            border-color: $red;
        }

        & + .form__error {
            margin-top: rem(10);
            color: $red;
        }
    }

    &__checkbox-group {
        text-align: center;
    }

    &__checkbox-label {
        text-transform: uppercase;
        font-weight: 500;
        font-size: rem(16);

        @include breakpoint($tablet) {
            font-size: rem(18);
            line-height: line-height(18, 21);
        }
    }

    &__link {
        color: map-get($map: $colors, $key: blue);
        font-weight: 500;
        font-size: rem(16);

        &:hover {
            text-decoration: underline;
        }

        @include breakpoint($tablet) {
            font-size: rem(18);
        }

        &--ticket {
            display: flex;
            width: 100%;

            padding: rem(12) rem(20);
            margin-top: rem(50);

            align-items: center;
            justify-content: center;

            text-transform: uppercase;
            color: $white;

            background-color: $vera-bg-color;

            border: rem(4) solid map-get($map: $colors, $key: raspberry);
            border-radius: rem(29);

            box-shadow: 0 0 rem(50) rem(5) rgba(226, 1, 96, 0.7);

            will-change: box-shadow;
            transition: box-shadow 0.2s linear;

            @include breakpoint($tablet) {
                margin-top: rem(70);
            }

            &:hover {
                text-decoration: none;

                box-shadow: 0 0 rem(80) rem(5) rgba(226, 1, 96, 0.7);
            }

            img {
                width: rem(31);
                height: rem(31);

                margin-left: rem(10);

                @include breakpoint($tablet) {
                    width: rem(62);
                    height: rem(62);

                    margin-left: rem(40);
                }
            }
        }
    }
}

.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: rem(20);
        display: inline-block;
        vertical-align: top;
        width: 20px;
        height: 20px;
        background: white;
    }

    // Box hover
    &:hover + label:before {
        background: map-get($map: $colors, $key: blue);
    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: map-get($map: $colors, $key: blue);
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 11px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
                2px 0 0 white,
                4px 0 0 white,
                4px -2px 0 white,
                4px -4px 0 white,
                4px -6px 0 white,
                4px -8px 0 white;
        transform: rotate(45deg);
    }
}

// Select2 overrides
.select2 {
    width: 100% !important;

    @include breakpoint($desktop) {
        max-width: rem(445);

        .form__block--longer & {
            max-width: 580px;
        }
    }


}

.select2-container .select2-selection--single {
    display: flex;
    height: rem(50);
    width: 100%;
    margin-left: auto;

    align-items: center;

    font-size: rem(20);

    background-color: transparent;
    border: rem(1) solid $white;
    border-radius: rem(40);

    &:focus:not(:focus-visible) {
        outline: none;
    }

    @include breakpoint($desktop) {
        height: rem(80);
        max-width: rem(445);

        font-size: rem(24);

        .form__block--longer & {
            max-width: 580px;
        }
    }
}

.ticket-claim {
    .select2-container .select2-selection--single {
        height: rem(50);
    }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: rem(20);

    color: $white;

    @include breakpoint($tablet) {
        padding-left: rem(40);
    }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: rem(14);
    right: rem(20);

    @include breakpoint($tablet) {
        top: 50%;
        right: rem(40);

        transform: translateY(-50%);
    }
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: $white transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $white transparent;
}

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
    background-color: map-get($map: $colors, $key: dark);
}

.select2-results__option {
    padding: rem(10) rem(20);
}

.select2-container--default .select2-results__option[aria-selected=true] {
    color: map-get($map: $colors, $key: dark);
}

.label-tooltip {
    @extend .question-icon;

    cursor: inherit;
}
