.get-ticket {
	&__title {
		@extend .text-outer-glow;

		text-transform: uppercase;
		font-family: rem(25);
		letter-spacing: rem(2);
		text-align: center;

		@include breakpoint($tablet) {
			font-family: rem(36);
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
	}

	&__image {
		margin: rem(80) 0 rem(60);

		align-self: center;

		.get-ticket--fail & {
			margin: rem(20) 0 rem(20);
		}
	}

	&__message {
		display: flex;

		flex-direction: column;

		p {
			@extend .text-outer-glow;

			margin-bottom: rem(20);

			text-align: center;
			letter-spacing: rem(2);
			font-size: rem(20);
			font-weight: 700;
			text-transform: uppercase;

			@include breakpoint($tablet) {
				margin-bottom: rem(35);

				font-size: rem(24);
			}
		}
	}

	&__mail {
		color: map-get($map: $colors, $key: green-1);

		.get-ticket--fail & {
			color: map-get($map: $colors, $key: raspberry);
		}
	}

	&__id {
		display: flex;
		min-width: rem(450);

		align-self: center;

		padding: rem(20);
		margin-bottom: rem(20);

		font-weight: 500;
		font-size: rem(25);

		justify-content: center;

		background-color: map-get($map: $colors, $key: raspberry);
		border-radius: rem(42);

		@include breakpoint($tablet) {
			margin-bottom: rem(35);
			padding: rem(25);

			font-size: rem(34);
		}
	}

	&__support {
		color: map-get($map: $colors, $key: blue);
		text-decoration: underline;

		will-change: color;
		transition: color 0.2s linear;

		&:hover {
			color: darken(map-get($map: $colors, $key: blue), 10%) !important;
		}
	}
}
