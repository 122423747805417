.copy-code {
  &-group {
    display: flex;
    width: 100%;

    margin-top: rem(35);
    padding: rem(5) rem(10);

    justify-content: space-between;
    align-items: center;

    border: rem(2) solid #fff;
    border-radius: rem(4);

    @include breakpoint($tablet) {
      width: 90%;

      padding: 0;
      margin-top: rem(15);

      border: none;
    }

    &.is-hidden {
      display: none;
    }

    img {
      height: rem(37);
      max-height: rem(37);

      position: relative;

      cursor: pointer;
    }
  }

  display: flex;
  width: 100%;
  height: rem(40);
  flex: 1;

  margin-right: rem(30);

  justify-content: flex-start;
  align-items: center;

  font-size: rem(14);
  font-weight: 600;
  color: map-get($map: $colors, $key: blue);
  text-overflow: ellipsis;

  background: transparent;
  border: none;

  &:focus {
	  outline: none;
  }

  &::selection {
	color: #000;

	background-color: #fff;
  }

  @include breakpoint($tablet) {
    padding: 0 rem(20);

    justify-content: center;

    border: rem(2) solid #fff;
    border-radius: rem(40);
  }
}

.send-code {
  &-group {
    display: flex;
    width: 100%;

    flex-direction: column;

    margin-top: rem(25);

    justify-content: center;
    align-items: center;

    @include breakpoint($tablet) {
      margin-top: rem(15);

      flex-direction: row;
    }
  }

  &-button {
    display: flex;
    width: 100%;
    max-width: rem(180);
    height: rem(35);

    padding: 0 rem(10);

    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-size: rem(14);
    font-weight: 500;

    background-color: map-get($map: $colors, $key: blue);
    border-radius: rem(30);

    cursor: pointer;
  }

  &-whatsapp {
    margin-top: rem(12);

    @include breakpoint($tablet) {
      margin-top: 0;
      margin-left: rem(12);
    }
  }
}
