.stats {
  display: flex;

  flex-direction: column;

  @include breakpoint($desktop) {
    flex-direction: row;

    justify-content: space-between;
  }

  &__item {
    display: flex;

    margin-bottom: rem(30);

    flex-direction: column;
    align-items: center;

    @include breakpoint($tablet) {
      min-width: rem(280);

      // margin-bottom: 0;

      justify-content: center;
    }

    @include breakpoint($desktop) {

      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    width: 100%;

    justify-content: center;

    // @include breakpoint($tablet) {
    //   justify-content: unset;
    // }
  }

  &__title {
    @extend .text-outer-glow;

    margin: 0 0 rem(20) 0;

    font-weight: 700;
    font-size: rem(22);
    letter-spacing: rem(2);
    text-transform: uppercase;

    @include breakpoint($tablet) {
      margin: 0 0 rem(30) 0;
    }
  }

  &__circle {
    width: rem(240);
    height: rem(240);

    position: relative;
  }

  .progressbar-text {
    width: 100%;
  }

  &__current,
  &__overall {
    @extend .text-outer-glow;
    margin: 0;

    font-weight: 700;
    letter-spacing: rem(2);
    text-transform: uppercase;
    text-align: center;
  }

  &__current {
    margin: 0 0 rem(15) 0;

    font-size: rem(60);
  }

  &__overall {
    font-size: rem(18);
  }
}

.rank {
  &-group {
    @extend .box;

    display: flex;
    width: 100%;
    max-width: rem(665);

    padding: rem(30) rem(35);

    flex-direction: column;

    @include breakpoint($tablet) {
      width: rem(665);

      padding: rem(60) rem(50);
    }
  }

  display: flex;

  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: rem(20);
  }

  &__semi {
    @extend .text-outer-glow;

    margin-bottom: rem(20);

    font-weight: 700;
    font-size: rem(18);

    &--bottom {
      margin-bottom: 0;
    }
  }

  &__position,
  &__name,
  &__points {
    @extend .text-outer-glow;

    font-weight: 700;
    font-size: rem(18);
    text-transform: uppercase;
    letter-spacing: rem(2);
  }

  &__position {
    padding-right: rem(10);
  }

  &__points {
    padding-left: rem(10);
  }

  &__name {
    flex: 1;
  }

  &__shadow {
    display: flex;
    width: 90%;

    margin: 0 auto;

    background-color: rgba(255, 255, 255, 0.2);
    filter: blur(8px);
  }

  &--user {
    color: $green;
  }
}
