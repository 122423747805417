.sales {
  display: flex;
  width: 100%;

  padding-top: rem(30);

  flex-direction: column;

  justify-content: center;
  align-items: center;

  @include breakpoint($tablet) {
    // width: rem(665);

    margin: 0 auto;
    padding-top: rem(85);

	flex-direction: row;
	flex-wrap: wrap;

    justify-content: center;
  }


  	@include breakpoint(1300px) {
		flex-wrap: nowrap;

		justify-content: space-between;
	}

 &__item {
   @extend .box;

   display: flex;
   width: 100%;
   max-width: rem(295);


   flex-direction: column;

   padding: rem(20);
   margin-bottom: rem(20);

   text-align: center;

   @include breakpoint($tablet) {
		min-height: rem(214);

		padding: rem(40);
		margin: 0 rem(20) rem(20) 0;

		&:nth-of-type(even) {
			margin-right: 0;
		}
   }

   	@include breakpoint(975px) {
		height: 100%;
		flex: 1;

		&:nth-of-type(even) {
			margin-right: rem(20);
		}
	}

   @include breakpoint(1300px) {
		margin-right: 0;
		margin-bottom: 0;

		&:nth-of-type(even) {
			margin-right: 0;
			margin-bottom: 0;
		}
	}

   h3 {
    @extend .text-outer-glow;

    margin: 0 0 rem(20) 0;

    font-weight: 700;
    font-size: rem(18);
    text-transform: uppercase;
    letter-spacing: 2px;

    @include breakpoint($tablet) {
      font-size: rem(22);
    }
  }

  p {
	@extend .text-outer-glow;
	display: flex;

	margin: auto 0 0;

	flex-direction: row;

    font-weight: 700;
    font-size: rem(45);
    text-transform: uppercase;
	letter-spacing: 2px;

	justify-content: center;

    @include breakpoint($tablet) {

      font-size: rem(60);
	}

	span {
		display: inline;

		margin-top: auto;
		margin-bottom: rem(10);

		font-size: rem(20);
	}
  }
 }
}
